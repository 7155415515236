import React, { useState, useEffect } from 'react';
import Navbar from './Navbar'; // Adjust the import path if needed
import bannerImage from '../assets/images/banner/1813637763.jpg';
import bannerImage1 from '../assets/images/banner/1130790436.jpg';

const HeaderBanner = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false); // Manage dropdown visibility

  const banners = [
    {
      title: (
        <>
          CLUE FINDER <br />
          DETECTIVE <br />
          AGENCY
        </>
      ),
      imageUrl: bannerImage,
    },
    {
      title: (
        <>
          The Best Private <br />
          Investigation <br />
          Services
        </>
      ),
      imageUrl: bannerImage1,
    },
  ];

  // Automatically change slide every 5 seconds
  useEffect(() => {
    const interval = setInterval(() => {
    }, 1000);
    return () => clearInterval(interval); // Cleanup on component unmount
  }, [currentSlide]);

  const nextSlide = () => {
    if (banners.length === 0) return;
    setCurrentSlide((prevSlide) => (prevSlide + 1) % banners.length);
  };
  
  const prevSlide = () => {
    if (banners.length === 0) return;
    setCurrentSlide((prevSlide) => (prevSlide - 1 + banners.length) % banners.length);
  };
  // Toggle the dropdown visibility when navbar item is clicked
  const handleDropdownToggle = () => {
    setIsDropdownVisible(!isDropdownVisible);
  };

  // Disable the dropdown when banner button is clicked
  const handleBannerButtonClick = () => {
    setIsDropdownVisible(false);
  };

  return (
    <div className="relative h-[700px] sm:h-[500px] lg:h-[800px] overflow-hidden">
      {/* Banner Section */}
      <div
        className="absolute inset-0 transition-transform duration-1000 ease-out"
        style={{
          backgroundImage: `url(${banners[currentSlide].imageUrl})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          animation: 'zoom 6s ease-in-out infinite',
        }}
      >
        {/* Navbar */}
        <div className="absolute top-0 left-0 w-full z-20">
          <Navbar 
            isDropdownVisible={isDropdownVisible}
            onDropdownToggle={handleDropdownToggle} 
          />
        </div>

        {/* Text Overlay */}
        <div className="absolute inset-0 bg-black bg-opacity-50 flex flex-col justify-center items-start text-white px-6 sm:px-12">
          <h1
            className="text-4xl sm:text-6xl lg:text-8xl font-bold mb-4 animate-fadeInDown"
            key={currentSlide} // Key ensures the animation re-runs on slide change
          >
            {banners[currentSlide].title}
          </h1>
        </div>
      </div>

      {/* Navigation Buttons */}
      <button
  onClick={prevSlide}
  aria-label="Previous Slide"
  className="absolute left-6 top-1/2 transform -translate-y-1/2 bg-white bg-opacity-50 p-4 hover:bg-opacity-70 z-30"
>
  <h1 className="text-3xl sm:text-5xl font-bold text-black">←</h1>
</button>
<button
  onClick={(e) => {
    nextSlide();
    handleBannerButtonClick?.(e); // Call handleBannerButtonClick if defined
  }}
  aria-label="Next Slide"
  className="absolute right-6 top-1/2 transform -translate-y-1/2 bg-white bg-opacity-50 p-4 hover:bg-opacity-70 z-30"
>
  <h1 className="text-3xl sm:text-5xl font-bold text-black">→</h1>
</button>


      <style jsx>{`
        @keyframes fadeInDown {
          0% {
            opacity: 0;
            transform: translateY(-30px);
          }
          100% {
            opacity: 1;
            transform: translateY(0);
          }
        }

        .animate-fadeInDown {
          animation: fadeInDown 1s ease-out;
        }
      `}</style>
    </div>
  );
};

export default HeaderBanner;

import React from "react";

const TextContent = () => {
  return (
    <div className="max-w-8xl mx-auto p-6 mt-8">
    <p className="text-[#868686] text-lg leading-7 mb-4">
      Hiring the right candidate is a critical decision that can
      significantly impact your business’s success. At{" "}
      <strong>Cluefinder Detective,</strong> our{" "}
      <strong>Pre Employment Investigation </strong> service is designed to
      provide employers with accurate and comprehensive information about
      potential employees before they are hired.
    </p>
    <p className="text-[#868686;] text-lg leading-7 mb-4">
Making a wrong hire can lead to financial loss, damage to your company's reputation, and potential legal issues. Our pre-employment investigations mitigate these risks by verifying the authenticity of the information provided by the candidate and ensuring they align with your company's values and requirements. We provide detailed reports that give you the clarity needed to make confident hiring decisions.
</p>
<p className="text-[#868686;] text-lg leading-7 mb-4">
Here’s how our pre-employment investigation process works:
</p>
<ul className=" mb-4 text-[#868686;]">
<li className="mb-2">
  <strong>Identity and Personal Background Check:</strong> We verify the candidate's personal information, 
  such as name, age, and identity, by cross-referencing official records and databases. This helps ensure 
  that the individual is who they claim to be, reducing the risk of identity fraud.
</li>
<li className="mb-2">
  <strong>Employment History Verification:</strong> We conduct thorough checks on the candidate's previous 
  employment, including verifying job titles, roles, responsibilities, and duration of employment. This 
  ensures that their resume accurately reflects their professional experience and prevents the possibility 
  of falsified work history.
</li>
<li className="mb-2">
  <strong>Educational and Qualification Verification:</strong> We confirm the authenticity of the candidate’s 
  academic degrees and professional certifications. This step is crucial in industries where specific 
  qualifications and credentials are mandatory for performing job duties.
</li>
<li className="mb-2">
  <strong>Criminal Record and Legal History Check:</strong> Ensuring workplace safety is paramount. We search 
  national and local criminal databases to check for any history of criminal activity, pending legal cases, 
  or past arrests. This is vital for maintaining a safe work environment and protecting your company from 
  potential legal liabilities.
</li>
<li className="mb-2">
  <strong>Financial Status and Credit Check (if relevant):</strong> For positions involving financial 
  responsibilities, it is important to understand the candidate’s financial background. We investigate their 
  credit history and financial stability to ensure they are fit for roles that require handling company funds 
  or sensitive financial data.
</li>
<li className="mb-2">
  <strong>Social and Professional Reputation Check:</strong> Understanding how a candidate is perceived in 
  their social and professional circles provides valuable insights into their character. We investigate their 
  behavior, interactions, and affiliations through discreet methods to assess their reputation and integrity.
</li>
</ul>
<p className="text-[#868686;] text-lg leading-7">
At <strong> Cluefinder Detective, </strong> we handle each pre-employment investigation with the highest level of confidentiality and professionalism. The candidate remains unaware of our investigation, ensuring that the hiring process continues smoothly without disruptions. Our detailed reports provide you with clear, reliable information, allowing you to make well-informed hiring decisions.
</p>      </div>
  );
};

export default TextContent;

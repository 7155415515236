import React from "react";
import Banner from "../component/PersonalServices/DivorceCase/DivorceCaseBanner";
import TextContent from "../component/PersonalServices/DivorceCase/DivorceCase";
const DivorceCase = () => {
  return (
    <div>
      <Banner />
      <TextContent />
    </div>
  );
};

export default DivorceCase;

import React from "react";

const TextContent = () => {
  return (
    <div className="max-w-8xl mx-auto p-6 mt-8">
    <p className="text-[#868686;] text-lg leading-7 mb-4">
    While hiring the right candidate is crucial, maintaining trust and integrity within your organization after employment is equally important. At <strong> Cluefinder Detective,</strong> our<strong> Post Employment Investigation </strong> service helps businesses monitor and verify the behavior, performance, and activities of employees even after they have joined your team. Whether you are dealing with suspicions of misconduct, corporate espionage, or a breach of company policies, our investigations provide critical insights to ensure that your workplace remains secure, transparent, and productive.  </p>
    <p className="text-[#868686;] text-lg leading-7 mb-4">
    In today’s competitive business environment, the risk of internal threats, such as fraud, data leaks, or unethical behavior, can have severe consequences for a company. Employers often face challenges in detecting these risks early. Our post-employment investigations are designed to identify these threats, providing you with actionable information to address issues promptly and prevent damage to your business.  </p>
    <p className="text-[#868686;] text-lg leading-7 mb-4">
    Here’s how our <strong>Post Employment Investigation </strong> works:
  
  </p>
    <ul className=" mb-4 text-[#868686;]">
      <li className="mb-2">
        <strong>Misconduct and Fraud Detection:
  </strong>
   If there are suspicions that an employee is involved in unethical behavior, fraud, or misconduct, we conduct a thorough investigation to gather evidence. This includes monitoring their activities, reviewing transactions, and identifying any signs of financial irregularities or breaches of trust.
      </li>
      <li className="mb-2">
        <strong>Breach of Contract or Confidentiality:</strong>
        Employees with access to sensitive company data or intellectual property are required to maintain confidentiality. If there are concerns about information leaks, data theft, or violations of non-compete agreements, we investigate to determine if any breaches have occurred.
      </li>
      <li className="mb-2">
        <strong>
        Surveillance and Behavior Monitoring:
              </strong>
              For cases where off-site or suspicious behavior is reported, we offer discreet surveillance services to monitor the employee’s actions. This includes checking for any external affiliations or activities that might conflict with your company’s interests or reveal misconduct.
      </li>
      <li className="mb-2">
        <strong>
        Reputation and Conduct Assessment:      </strong>
        Sometimes, employees may present themselves differently within the organization compared to how they behave externally. We assess their reputation outside the workplace, checking for any associations, social habits, or activities that could harm your company’s image or lead to negative consequences.
      </li>
      <li className="mb-2">
        <strong>
        Financial Misconduct Investigations:
              </strong>
              Employees in positions of financial responsibility may become involved in embezzlement or financial fraud. We investigate discrepancies in financial records, personal gains from company funds, or unauthorized transactions to ensure the integrity of your financial operations.
      </li>
      <li className="mb-2">
        <strong>
        Exit Interviews and Post-Exit Investigations:
              </strong> 
              When employees leave your company, especially those in key positions, there may be concerns about what they take with them. We investigate any potential data theft, client poaching, or violations of agreements post-exit to protect your business from future risks.
      </li>
    </ul>
  </div>
  );
};

export default TextContent;

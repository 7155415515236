import React from "react";

const TextContent = () => {
  return (
    <div className="max-w-8xl mx-auto p-6">
    <h2 className="text-2xl font-semibold mb-4">Background Check by Cluefinder – Verify Trust, Make Informed Decisions</h2>
    <h3 className="text-[#1c1c1c] text-lg leading-7 mb-4">
      At <strong>Cluefinder Detective Agency,</strong> we understand the importance of trust in both personal and professional relationships. 
      Our <strong>Background Check</strong> services help you make informed decisions by providing a thorough examination of an individual's history, ensuring there are no surprises down the road.
    </h3>
    
    <h4 className="text-xl font-medium mb-2">What We Investigate:</h4>
    <ul className="list-disc pl-6 text-lg leading-7">
      <li>✅ Criminal Records – Ensure the person has a clean legal history.</li>
      <li>✅ Employment History – Verify job details, professional reputation, and work ethics.</li>
      <li>✅ Financial Stability – Check for any signs of financial instability or bankruptcy.</li>
      <li>✅ Social Media & Online Presence – Analyze digital footprints for any red flags.</li>
      <li>✅ Education & Qualifications – Confirm academic and professional credentials.</li>
    </ul>
    
    <h3 className="text-[#1c1c1c] text-lg leading-7 mt-4">
      Our team at Cluefinder uses in-depth research and advanced investigative techniques to provide accurate, reliable results. 
      We handle every case with the utmost discretion and professionalism to ensure your peace of mind.
    </h3>
  </div>
  );
};

export default TextContent;

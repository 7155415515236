import React from "react";
import Banner from "../component/PersonalServices/LoyaltyChecks/LoyaltyBanner";
import TextContent from "../component/PersonalServices/LoyaltyChecks/Loyalty";
const LoyaltyChecksPages = () => {
  return (
    <div>
      <Banner />
      <TextContent />
    </div>
  );
};

export default LoyaltyChecksPages;

import React from "react";
import backgroundImage from "../assets/images/background/video-1.jpg"; // Adjust path to your image

const Banner = () => {
  return (
    <div>
      {/* Sticky Banner Section */}
      <div className="relative mt-[20vh]">
        {/* Text positioned on the image */}
        <div className="absolute left-4 top-1/2 transform -translate-y-1/2 z-10 text-white px-4 sm:px-8 md:px-16">
          <h1
            className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl mt-10 sm:mt-26 font-extrabold"
            style={{
              fontFamily: "'Rajdhani', sans-serif",
              color: "white",
              margin: 5,
            }}
          >
            Our Agency Trusted by <br /> More than 9,875 <br /> Clients
          </h1>
        </div>

        {/* Background Image */}
        <div
          className="relative w-full h-[300px] sm:h-[400px] bg-cover bg-center"
          style={{ backgroundImage: `url(${backgroundImage})` }}
        >
          <div className="absolute inset-0 bg-black bg-opacity-60"></div>
        </div>
      </div>

      {/* Overlaying Blue Background */}
      <div className="flex flex-col items-center justify-center">
        <div className="relative w-full sm:w-[90%] max-w-screen-xl h-[150px] sm:h-[200px] flex bg-blue-900 sticky -mt-[100px] z-50 text-white">
          <div className="w-full flex items-center justify-around px-4 md:px-10">
            {/* Statistic Boxes */}
            {[
              { number: 660, label: "Closed Cases" },
              { number: 94, label: "Pending Cases" },
              { number: 70, label: "Investigators" },
              { number: 880, label: "Happy Clients" },
            ].map((item, index) => (
              <div
                key={index}
                className={`flex flex-col items-center ${
                  index !== 3 ? "border-r-2 border-white pr-4 sm:pr-8" : ""
                }`}
              >
                <p className="text-xl sm:text-2xl md:text-4xl font-bold">
                  {item.number}
                </p>
                <p className="text-sm md:text-base font-semibold">
                  {item.label}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;

import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './component/Header'; // Adjust the import based on your actual path
import Home from './pages/Home';
import StrickNavbar from './component/StrickNavbar';
import Footer from './component/Footer';
import About from './pages/About';
import PreMatrimonial from './pages/PreMatrimonial';
import PostMatrimonial from './pages/PostMatrimonial';
import LoyaltyChecks from './pages/LoyaltyChecks';
import DivorceCase from './pages/DivorceCase';
import Surveillance from './pages/Surveillance';
import BackgroundCheck from './pages/BackgroundCheck';
import StingOperation from './pages/StingOperation';
// import CorporateServices from './pages/CorporateServices';
import PreEmployment from './pages/PreEmployment';
import PostEmployment from './pages/PostEmployment';
import LabourCourtCases from './pages/LabourCourtCases';
import UndercoverAgent from './pages/UndercoverAgent';
import Blog from './pages/BlogPage'
import ContactUsPage from './pages/ContactUsPage'


function App() {
  const [showNavbar, setShowNavbar] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setShowNavbar(true);
      } else {
        setShowNavbar(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <Router>
      <div className="App">
        {/* Render the Header component */}
        <Header />
        {/* Conditional rendering of the StrickNavbar */}
        {showNavbar && (
          <div className="fixed top-0 left-0 w-full z-50">
            <StrickNavbar />
          </div>
        )}

        <Routes>
        <Route path="/" element={<Home />} /> {/* Default route */}
        <Route path="/about" element={<About />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/contact" element={<ContactUsPage />} />
          <Route path="/pre-matrimonial" element={<PreMatrimonial />} />
          <Route path="/post-matrimonial" element={<PostMatrimonial />} />
          <Route path="/loyalty-checks" element={<LoyaltyChecks />} />
          <Route path="/divorce-case" element={<DivorceCase />} />
          <Route path="/surveillance" element={<Surveillance />} />
          <Route path="/background-check" element={<BackgroundCheck />} />
          <Route path="/sting-operation" element={<StingOperation />} />

          <Route path="/pre-employment" element={<PreEmployment />} />
          <Route path="/post-employment" element={<PostEmployment />} />
          <Route path="/labour-court-cases" element={<LabourCourtCases />} />
          <Route path="/undercover-agent" element={<UndercoverAgent />} />
        </Routes>

        {/* Render the Footer component */}
        <Footer />
      </div>
    </Router>
  );
}

export default App;

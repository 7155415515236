import React from "react";
import imageCard from "../assets/images/uploads/personal.png";

const DetectiveServices = () => {
  return (
    <div className="bg-gray-100 py-10 px-4 sm:px-8 lg:px-16">
      {/* Main Container */}
      <div className="max-w-7xl mx-auto">
        {/* Header Section */}
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 items-center">
          {/* Left Section */}
          <div>
            <h2
              className="text-2xl sm:text-3xl md:text-4xl font-bold relative"
              style={{
                fontFamily: "'Rajdhani', sans-serif",
                color: "#1c1c1c",
              }}
            >
              <span className="text-[#03076a]">__</span>
              <span className="relative ml-2">Cluefinder Detective</span>
              <br />
              <span className="block ml-10 text-lg sm:text-xl md:text-2xl">
                Comprehensive Investigation Services
              </span>
            </h2>
          </div>

          {/* Right Section */}
          <div>
            <p className="text-gray-600 font-rajdhani text-sm sm:text-base md:text-lg leading-6 sm:leading-7 md:leading-8 font-medium">
              Cluefinder Detective offers discreet and professional
              investigation services, including background checks, surveillance,
              fraud detection, and personal investigations. With expert
              detectives, we ensure thorough, confidential solutions tailored to
              your needs.
            </p>
          </div>
        </div>

        {/* Cards Section */}
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 lg:gap-10 mt-12">
  {/* Personal Detective Services Card (Large) */}
  <div className="bg-white shadow-md border rounded-lg overflow-hidden hover:shadow-lg transition-all duration-300 sm:col-span-2 lg:col-span-1 lg:row-span-2">
    <div className="flex flex-col items-center p-6">
      <img
        src={imageCard}
        alt="Personal Detective"
        className="h-32 w-32 sm:h-40 sm:w-40 object-cover"
      />
      <h2 className="mt-4 text-black text-xl sm:text-2xl font-bold text-center">
        Personal Detective Services
      </h2>
    </div>
    <ul className="mt-4 text-gray-600 px-6 text-sm sm:text-base">
      <li className="mb-2 text-center">Pre Matrimonial Investigation</li>
      <li className="mb-2 text-center">Post Matrimonial</li>
      <li className="mb-2 text-center">Loyalty Checks</li>
      <li className="mb-2 text-center">Divorce Case Investigation</li>
      <li className="mb-2 text-center">Physical Surveillance</li>
      <li className="mb-2 text-center">Background Check</li>
      <li className="text-center">Sting Operation</li>
    </ul>
    <div className="bg-gray-200 mt-6 py-4 text-center">
      <button className="text-gray-800 text-sm sm:text-base font-medium">
        Learn More
      </button>
    </div>
  </div>

  {/* Corporate Detective Services Card (Small) */}
  <div className="bg-white shadow-md border rounded-lg overflow-hidden hover:shadow-lg transition-all duration-300">
    <div className="flex flex-col items-center p-6">
      <img
        src={imageCard}
        alt="Corporate Detective"
        className="h-32 w-32 sm:h-40 sm:w-40 object-cover"
      />
      <h2 className="mt-4 text-black text-lg sm:text-xl font-bold text-center">
        Corporate Detective Services
      </h2>
    </div>
    <ul className="mt-4 text-gray-600 px-6 text-sm sm:text-base">
      <li className="mb-2 text-center">Pre Employment</li>
      <li className="mb-2 text-center">Post Employment</li>
      <li className="mb-2 text-center">Labour Court Cases</li>
      <li className="text-center">Undercover Agent</li>
    </ul>
    <div className="bg-gray-200 mt-6 py-4 text-center">
      <button className="text-gray-800 text-sm sm:text-base font-medium">
        Learn More
      </button>
    </div>
  </div>
</div>

      </div>
    </div>
  );
};

export default DetectiveServices;

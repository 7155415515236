import React from "react";

const AboutUsComponent = () => {
  return (
    <div className="main-container" style={{ padding: "20px" }}>
      {/* First Container: Two Divs */}
      <div className="top-container" style={{ display: "flex", gap: "20px" }}>
        {/* Right: Image (Initially on the Right) */}
        <div
          className="image-container"
          style={{
            flex: 1,
            maxWidth: "400px",
            height: "auto",
          }}
        >
          <img
            src={require("../../assets/images/resource/13220382.jpeg")}
            alt="About Cluefinder"
            style={{
              width: "100%",
              height: "auto",
              objectFit: "cover",
            }}
          />
        </div>

        {/* Left: Content */}
        <div
          className="content-container"
          style={{
            flex: 2,
            fontFamily: "Arial, sans-serif",
            color: "#333",
          }}
        >
          <h2
            style={{
              fontSize: "3rem", // Increased size
              fontWeight: "bold",
              textAlign: "center", // Center the heading
              color: "#03076a", // Heading color
            }}
          >
            Why Choose Cluefinder?
          </h2>
          <p style={{ fontSize: "1.2rem" }}> {/* Increased size */}
            One of Cluefinder's standout qualities is its local presence in
            Punjab, especially in Chandigarh. This deep-rooted connection
            allows the agency to leverage its understanding of the local
            culture, customs, and legal frameworks. This knowledge ensures that
            investigations are conducted efficiently and in compliance with
            regional laws.
          </p>
          <h3 style={{ fontSize: "2rem", fontWeight: "bold", marginTop: "20px", color: "#03076a" }}> {/* Increased size */}
            Cluefinder provides a wide range of investigative services,
            including:
          </h3>
          <ul style={{ fontWeight: "bold", marginTop: "10px" }}>
            <li>• Background Checks</li>
            <li>• Surveillance Operations</li>
            <li>• Matrimonial Investigations</li>
            <li>• Corporate Investigations</li>
            <li>• Locating Missing Persons</li>
          </ul>
        </div>
      </div>

      {/* Second Container: Cluefinder Overview */}
      <div className="bottom-container" style={{ marginTop: "40px" }}>
        <h2 style={{ fontSize: "2.5rem", fontWeight: "bold", color: "#03076a" }}>
          About Cluefinder
        </h2>
        <p style={{ fontSize: "1.0rem" }}>
          Cluefinder stands as a leading private detective agency operating
          across the vibrant regions of Chandigarh, Punjab, and Himachal Pradesh.
          Headquartered in Chandigarh and Zirakpur, Cluefinder has earned its
          reputation as one of the oldest and most reputable private
          investigation organizations in Punjab. With a commitment to reliability,
          discretion, and affordability, Cluefinder delivers exceptional
          investigative services tailored to client needs.
        </p>
        <p style={{ fontSize: "1.0rem" }}>
          Founded by Detective Vipin Maurya, the Managing Director, the agency
          boasts over 9 years of extensive experience in private investigation.
          Detective Maurya envisioned Cluefinder as a solution for individuals
          seeking factual evidence to navigate complex dilemmas. His goal was to
          provide easy, accessible, and supportive services to those facing
          challenging situations, helping them make informed decisions with
          confidence.
        </p>
        <p style={{ fontSize: "1.0rem" }}>
          Throughout its existence, Cluefinder has garnered a remarkable
          reputation for professionalism and discretion. Clients across Chandigarh,
          Punjab, and Himachal Pradesh trust Cluefinder to handle their cases with
          the utmost care and confidentiality. The agency's ability to protect
          client privacy while delivering accurate results has cemented its position
          as a trusted name in private investigation.
        </p>

        <h3 style={{ fontSize: "2rem", fontWeight: "bold", marginTop: "20px", color: "#03076a" }}>
          Expert Team of Investigators
        </h3>
        <p style={{ fontSize: "1.0rem" }}>
          The success of Cluefinder lies in its team of dedicated and experienced
          detectives. Each investigator possesses specialized skills, refined
          through rigorous training to stay updated with the latest investigative
          techniques and technological advancements. By adhering to a strict code of
          ethics, Cluefinder ensures all operations meet legal and moral standards.
        </p>

        <h3 style={{ fontSize: "2rem", fontWeight: "bold", marginTop: "20px", color: "#03076a" }}>
          Affordable & Accessible Services
        </h3>
        <p style={{ fontSize: "1.0rem" }}>
          Cluefinder believes in providing high-quality services without financial
          barriers. Competitive pricing ensures that individuals and organizations,
          regardless of their budget, can access professional investigative support.
        </p>

        <h3 style={{ fontSize: "2rem", fontWeight: "bold", marginTop: "20px", color: "#03076a" }}>
          Community Contribution
        </h3>
        <p style={{ fontSize: "1.0rem" }}>
          Beyond individual cases, Cluefinder actively contributes to the safety and
          security of the communities it serves. By collaborating with local law
          enforcement agencies, the agency provides valuable support in solving
          crimes, apprehending criminals, and preventing illegal activities. Their
          expertise in areas like organized crime, cybercrime, and drug trafficking
          has been instrumental in maintaining law and order in the region.
        </p>

        <h3 style={{ fontSize: "2rem", fontWeight: "bold", marginTop: "20px", color: "#03076a" }}>
          Our Commitment
        </h3>
        <p style={{ fontSize: "1.0rem" }}>
          Cluefinder remains dedicated to unraveling mysteries and providing
          tangible solutions to individuals and businesses across Northern India.
          With a focus on reliability, professionalism, and client satisfaction,
          Cluefinder is your trusted partner in truth and justice.
        </p>
      </div>

      {/* Responsive Styles */}
      <style>
        {`
          .main-container {
            display: flex;
            flex-direction: column;
            gap: 20px;
          }

          .top-container {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
          }

          @media (max-width: 600px) {
            .top-container {
              flex-direction: column;
              align-items: center;
            }
            .image-container {
              order: -1; /* Move the image to the top */
              width: 100%;
              max-width: 250px; /* Reduce image size further */
              margin-bottom: 20px;
            }
            .content-container {
              width: 100%;
            }
            img {
              width: 100%;
              max-width: 250px; /* Keep the image responsive with reduced size */
              margin: 0 auto;
            }
          }

          .bottom-container {
            width: 100%;
            font-family: Arial, sans-serif;
          }

          h2, h3 {
            text-align: center; /* Center all h2 and h3 elements */
          }

          p {
            text-align: justify; /* Align paragraphs for readability */
          }
        `}
      </style>
    </div>
  );
};

export default AboutUsComponent;

import React from 'react';
import Image2 from '../assets/images/cluefinderimage.png';

const Welcome = () => {
  return (
    <div className="flex flex-col md:flex-row items-center md:items-start justify-between p-4 md:p-8 space-y-6 md:space-y-0">
      {/* Image Box 2 */}
      <div className="w-full md:w-[50%] h-full md:h-auto border-t-[20px] border-l-[20px] border-white overflow-hidden">
        <img
          src={Image2}
          alt="Detective 2"
          className="w-full h-full object-cover"
        />
      </div>

      {/* Right Section - Text */}
      <div className="flex-1 mt-[4vh] md:mt-0 md:pl-8">
        <div className="flex flex-col items-start">
          <h2
            className="text-3xl md:text-5xl font-semibold mb-4"
            style={{ fontFamily: 'Rajdhani, sans-serif' }}
          >
            <span
              className="text-xl md:text-3xl font-semibold text-black block mt-4 "
              style={{
                lineHeight: '0.5',
                fontFamily: "'Rajdhani', sans-serif",
              }}
            >
                        <span className="text-[#03076a]">__</span>

              Welcome To Cluefinder Detective
            </span>
          </h2>
        </div>
        <p
          className="text-[#868686] text-base md:text-xl font-light bg-[#ffffff]"
          style={{
            fontFamily:
              '-apple-system, BlinkMacSystemFont, sans-serif',
            lineHeight: '2',
          }}
        >
          At{' '}
          <span className="font-bold">Cluefinder Detective,</span> we specialize
          in providing top-notch investigative services tailored to the unique
          needs of Zirakpur and the greater Tricity region. With a deep
          understanding of local challenges and a passion for uncovering the
          truth, we offer a wide range of services, from personal investigations
          to corporate inquiries. Our team of skilled detectives combines
          cutting-edge technology with years of experience to ensure precise,
          discreet, and effective results. Whether you're seeking clarity in
          personal matters or need critical business intelligence, Cluefinder
          Detective is your trusted partner in solving mysteries.
        </p>
        <div className="flex items-center space-x-4 mt-[20vh]">
        </div>
      </div>
    </div>
  );
};
export default Welcome;

import React from "react";
import Banner from "../component/PersonalServices/BackgroundCheck/BackGroundBanner";
import TextContent from "../component/PersonalServices/BackgroundCheck/Background";
const PreEmploymentPage = () => {
  return (
    <div className="background-check">
    <Banner />
    <TextContent />     
    </div>
  );
};

export default PreEmploymentPage;

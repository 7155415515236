import React from "react";
import Banner from "../component/PersonalServices/StingOperation/StringOperationBanner";
import TextContent from "../component/PersonalServices/StingOperation/String";

const PreMatrimonialPage = () => {
  return (
    <div>
      <Banner />
      <TextContent />
    </div>
  );
};

export default PreMatrimonialPage;

import React from "react";

const TextContent = () => {
  return (
    <div className="max-w-8xl mx-auto p-6">
    <h2 className="text-2xl font-bold text-gray-800 mb-4">
      Post Matrimonial Investigation by Cluefinder – Uncover the Truth, Rebuild Trust
    </h2>
    <p className="text-[#868686] text-lg leading-7 mb-4">
      Marital life can sometimes raise doubts or concerns that need clarity. At Cluefinder Detective Agency, our Post Matrimonial Investigation services are designed to address your uncertainties with utmost professionalism and discretion.
    </p>
    <h3 className="text-lg font-bold text-[#868686] mb-4">What We Investigate:</h3>
    <ul className="list-disc pl-6 text-[#868686] text-lg leading-7 mb-4">
      <li>✅ <span className="font-bold">Suspicion of Infidelity</span> – Verify loyalty with factual evidence.</li>
      <li>✅ <span className="font-bold">Behavioral Changes</span> – Uncover reasons for sudden shifts in habits or attitudes.</li>
      <li>✅ <span className="font-bold">Hidden Relationships</span> – Detect any undisclosed associations or affairs.</li>
      <li>✅ <span className="font-bold">Financial Irregularities</span> – Identify unexplained expenses or hidden assets.</li>
      <li>✅ <span className="font-bold">Workplace Activities</span> – Investigate any suspicious behavior at the workplace.</li>
    </ul>
    <p className="text-[#868686] text-lg leading-7 mb-4">
      At Cluefinder, we handle every case with sensitivity, ensuring confidentiality while delivering accurate and actionable insights. Our aim is to help you make informed decisions about your future.
    </p>
  </div>
  );
};

export default TextContent;

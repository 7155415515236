import React from "react";
import { FaBrain } from "react-icons/fa";
import "@flaticon/flaticon-uicons/css/all/all.css"; // Import Flaticon styles

const HorizontalCards = () => {
  return (
    <div className="relative z-100 sticky  -mt-24">
      <div className="flex flex-col md:flex-row w-full justify-center ">
        {/* First Card - Blue */}
        <div className="bg-[#03076a] text-white p-6 flex flex-col md:flex-row items-center w-full max-w-xl">
          <div className="w-20 h-20 md:w-32 md:h-32 flex items-center justify-center mb-4 md:mb-0">
            <FaBrain className="text-white text-5xl md:text-7xl" />
          </div>
          <div className="text-center md:text-left">
            <p className="text-lg md:text-xl ml-0 md:ml-2">
              SEEKING SERVICES
            </p>
            <h3 className="text-2xl md:text-3xl mt-2 font-semibold">
              Know the Truth for Peace of Mind
            </h3>
          </div>
        </div>

        {/* Second Card - Black */}
        <div className="bg-[#212529] text-white p-6 flex flex-col md:flex-row items-center w-full max-w-2xl">
          <div className="w-20 h-20 md:w-32 md:h-32 flex items-center justify-center mb-4 md:mb-0">
            <i className="fi-rr-user text-[#03076a] text-4xl md:text-6xl"></i>
          </div>
          <div className="text-center md:text-left">
            <p className="text-lg md:text-xl ml-0 md:ml-2">
              WE'RE READY TO HELP
            </p>
            <h3 className="text-2xl md:text-3xl mt-2 font-semibold">
              Let's Hire a Private Investigator
            </h3>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HorizontalCards;

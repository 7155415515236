// src/pages/Home.js
import React from 'react';
import HorizontalCards from '../component/HorizontalCards';
import Welcome from '../component/Welcome';
import DetectiveServices from '../component/DetectiveServices'
import DetectiveServiceCards from '../component/DetectiveServiceCards'
import CorporateDetective from '../component/CorporateDetective'
import FAQSection from '../component/FAQSection';
import Client from '../component/Client'
import OurClients from '../component/OurClients'
import Banner from '../component/Banner'
// import Navbar from '../component/Navbar'
const Home = () => {
  return (
    <div className="home-page">
            <Banner />
                  {/* <Navbar /> */}

      <HorizontalCards />
      <Welcome />
      <DetectiveServices />
      <DetectiveServiceCards />
      <CorporateDetective />
      <FAQSection />
      <Client />
      <OurClients/>
    </div>
  );
};

export default Home;

import React from "react";
import Banner from "../component/PersonalServices/PostMatrimonial/PostBanner";
import TextContent from "../component/PersonalServices/PostMatrimonial/PostMatri";
const PostMatrimonialPage = () => {
  return (
    <div className="post-matrimonial">
    <Banner />
    <TextContent />
    </div>
  );
};

export default PostMatrimonialPage;

import { useState } from "react";
import Chooseus from "../assets/images/resource/chooseus-1.jpg";

const FAQSection = () => {
  const [activeAnswer, setActiveAnswer] = useState(null);

  const toggleAnswer = (index) => {
    setActiveAnswer(activeAnswer === index ? null : index);
  };

  // List of all questions and answers
  const faqs = [
    {
      id: 1,
      question: "What is the background of your MD & What kind of Experience do they have?",
      answer: "Our MD has over 9-10 years of experience in the investigative field, specializing in both personal and corporate investigations. With a strong background in criminal justice and business management, they have led Cluefinder with a focus on integrity, innovation, and client satisfaction."
    },
    {
      id: 3,
      question: "Have your Investigators taken any classes?",
      answer: "Yes, all our investigators undergo regular training in various investigative techniques, including surveillance, cybercrime analysis, and legal protocols. They are also certified by industry-standard institutions."
    },
    {
      id: 4,
      question: "What about the confidentiality?",
      answer: "Confidentiality is our top priority. All information shared with Cluefinder remains strictly confidential and is protected by non-disclosure agreements. We take extra measures to ensure that your privacy is maintained at all times."
    },
    {
      id: 5,
      question: "What about the Authenticity of information which I will share with you & Who else will you share the information?",
      answer: "We ensure the authenticity of all the information shared with us and guarantee that it will not be disclosed to anyone other than the designated personnel handling your case, unless required by law. We value your trust and maintain a strict code of confidentiality."
    },
    {
      id: 6,
      question: "How much does it cost?",
      answer: "The cost varies depending on the complexity and nature of the case. We provide customized pricing based on your specific requirements and offer transparent quotes."
    },
    {
      id: 7,
      question: "How much time do you take to complete one case?",
      answer: "The time taken depends on the complexity of the case, but we typically aim to complete investigations within a few days to weeks. We keep you updated on the progress throughout."
    },
    {
      id: 8,
      question: "How do I pay you?",
      answer: "Payments can be made through bank transfers, credit/debit cards, or online payment gateways. We provide a secure payment method for your convenience."
    },
    {
      id: 9,
      question: "How will you give us the report?",
      answer: "We deliver detailed reports in digital format (PDF or video) via email or through secure channels. Hard copies can be provided upon request."
    }
  ];

  return (
    <div className="mt-[7vh]">
      <div className="flex flex-col md:flex-row p-6 mr-4">
        {/* Left Image */}
        <div className="w-full md:w-1/2">
          <img
            src={Chooseus}
            alt="Detective"
            className="w-full h-full object-cover mt-0 md:ml-[6vh]"
          />
        </div>

        {/* FAQ Section */}
        <div className="w-full md:w-1/2 mt-4 md:mt-0">
          <h1
            className="text-3xl md:text-4xl mt-8 md:mt-20 relative transition-all duration-500 ease-linear"
            style={{
              fontFamily: "'rajdhani sans-serif",
              fontWeight: 800,
              color: "#1c1c1c",
              margin: 0,
            }}
          >
            <span className="text-blue-500 ml-[8vh]">__</span>
            <span className="relative ml-2">Frequently Asked Questions</span>
            <br />
          </h1>

          <div className="space-y-4">
            {faqs.map((faq) => (
              <div key={faq.id} className="border-2 ml-[8vh] mt-4 py-2">
                <div className="py-2">
                  <h3
                    className="text-xl md:text-2xl ml-2 text-black font-bold cursor-pointer hover:underline"
                    onClick={() => toggleAnswer(faq.id)} // Toggle answer for specific question
                  >
                    {faq.id}. {faq.question}
                  </h3>
                  {activeAnswer === faq.id && (
                    <p
                      className="text-[#868686] text-lg ml-4 mr-4 line-height-[50px] bg-[#ffffff] font-light"
                      style={{
                        fontFamily:
                          '--font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";',
                        fontSize: "20px",
                        lineHeight: "40px",
                        fontWeight: 400,
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center top",
                        WebkitFontSmoothing: "antialiased",
                      }}
                    >
                      {faq.answer}
                    </p>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FAQSection;

import React from "react";
import Banner from "../component/CorporateServices/LabourCourt/LabourBanner";
import TextContent from "../component/CorporateServices/LabourCourt/Labour";

const LabourCourtPage = () => {
  return (
    <div>
      <Banner />
      <TextContent />
    </div>
  );
};

export default LabourCourtPage;
